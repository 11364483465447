@font-face {
  font-family: 'RockfordSansLight';
  src: local('RockfordSansLight'), url(./Assets/fonts/RockfordSans-Light.otf) format('opentype');
}

@font-face {
  font-family: 'RockfordSansRegular';
  src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'RockfordSansMedium';
  src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'RockfordSansBold';
    font-weight: 900;
    src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'RockfordSansExtraBold';
    font-weight: 900;
    src: local('RockfordSans'), url(./Assets/fonts/RockfordSans-ExtraBold.otf) format('opentype');
}

body {
  font-size: initial !important;
  margin: 0;
  font-family: RockfordSansRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus {
  outline:0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

select {
  cursor: pointer;
}

.public-DraftEditor-content {
  border: 1px solid #e6e6e6;
  padding: 10px;
  padding-top: 0px;
  border-radius: 6px;
  min-height: 297px;
}

.rdw-editor-toolbar {
  margin-bottom: 20px !important;
}

.public-DraftEditor-content:focus {
  border: 1px solid #000;
}

a:hover {
  color: #000 !important;
}